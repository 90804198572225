<template>
  <div>
    <b-modal
      v-model="showSwitchDateModal"
      :title="$t('settings.analytics.switchDateModalTitle')"
      ok-only
      ok-variant="primary"
      :ok-title="$t('buttons.ok')"
      centered
    >
      <div class="text-center p-4">
        <p v-html="$t('settings.analytics.switchDateModalText', { switchDate: switchDateFormat })"></p>
        <b-button
          variant="link"
          class="mt-3"
          @click="openEmail"
        >
          {{ $t('settings.analytics.contactSupport') }}
        </b-button>
      </div>
    </b-modal>
    <b-row class="row mb-4 mt-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <div class="d-flex justify-content-between">
          <h3>{{ $t('settings.analytics.name') }}</h3>
          <date-range-picker
            ref="picker"
            opens="left"
            :locale-data="localeData"
            :minDate="minDate"
            :singleDatePicker="false"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="false"
            v-model="dateRange"
            @update="updateValues"
          >
            <template v-slot:input="picker">
              <div style="min-width: 350px;">
                {{ picker.startDate | inputDate }} - {{ picker.endDate | inputDate }}
                <b-icon class="pl-1 dropdown-icon" icon="caret-down-fill" aria-hidden="true"></b-icon>
              </div>
            </template>
          </date-range-picker>
        </div>
        <hr>
        <p v-html="$t('settings.analytics.descPirsch')"></p>
      </b-col>
    </b-row>
    <b-row class="row mb-4 mt-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-row v-if="queryErrors.globalStats" class="global-stats mb-5">
          <b-col cols="12">
            <h4>Global stats</h4>
            <div class="query-error">
              {{ $t('settings.analytics.globalStatsQueryError') }}
            </div>
          </b-col>
        </b-row>
        <b-row v-else-if="globalStats" class="row global-stats mb-5">
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.visitors') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-visitors"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip target="tooltip-global-visitors">
                {{ $t('settings.analytics.pirschVisitorsTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.visitors }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.views') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-views"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip target="tooltip-global-views">
                {{ $t('settings.analytics.viewsTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.views }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.avgTime') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-avg-time"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip target="tooltip-global-avg-time">
                {{ $t('settings.analytics.pirschAvgPageTimeTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.avgTime | timeString }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5 id="global-bounce-rate">
              {{ $t('settings.analytics.bounceRate') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-bounce-rate"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip target="tooltip-global-bounce-rate">
                {{ $t('settings.analytics.bounceRateTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.bounceRate }}</div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.readsPerDay') }}</h4>
        <div v-if="queryErrors.readsPerDayObject" class="query-error">
          {{ $t('settings.analytics.readsPerDayQueryError') }}
        </div>
        <LineChart
          v-else-if="readsPerDayObject"
          :key="readsPerDayKey"
          :reads-per-day-object="readsPerDayObject"
          :dateRange="dateRange"
        />
        <p v-else>{{ $t('settings.analytics.noReadsPerDay') }}</p>
      </b-col>
    </b-row>
    <b-row v-if="queryErrors.projectOrFolders" class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.projectsOrFolders') }}</h4>
        <div class="query-error">
          {{ $t('settings.analytics.projectOrFoldersQueryError') }}
        </div>
      </b-col>
    </b-row>
    <b-row v-else-if="itemType === 'folder' && projectOrFolders.length" class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.projectsOrFolders') }}</h4>

        <b-table
          :key="folderOrProjectTableKey"
          :fields="projectAndFolderFields"
          :items="projectOrFolders"
          borderless
          dark
          fixed
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data">
            <div
              v-if="data.item.type === 'folder'"
              class="cell-folder cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon icon="folder" aria-hidden="true" scale="1"></b-icon>
            </div>
            <div
              v-else-if="data.item.type === 'project' && !data.item.image"
              class="cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon icon="files" aria-hidden="true" scale="1"></b-icon>
            </div>
            <div
              v-else-if="data.item.type === 'project' && data.item.image"
              class="cell-background-image"
              :style="`background-image: ${data.item.image}`"
            ></div>
          </template>
          <template #cell(timeOnPage)="data">
            {{ data.item.timeOnPage | timeString }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="queryErrors.pages" class="mb-5">
      <b-col cols="12" offset-sm="1" sm="5">
        <h4>{{ $t('settings.analytics.pages') }}</h4>
        <div class="query-error">
          {{ $t('settings.analytics.pagesQueryError') }}
        </div>
      </b-col>
    </b-row>
    <b-row v-else-if="pages && pages.length" class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.pages') }}</h4>
        <b-table
          :key="pageTableKey"
          :fields="fields"
          :items="pages"
          borderless
          dark
          fixed
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data">
            <div
              class="cell-background-image"
              :style="{
                backgroundImage: data.item.imageObject.imageUrl,
                backgroundPosition: data.item.imageObject.focalpoint,
              }"
            ></div>
          </template>
          <template #cell(name)="data">
            <div class="name-cell">
              <div class="name-wrapper">
                {{ data.item.name }}
              </div>
            </div>
          </template>
          <template #cell(viewers)="data">
            <div class="viewers-cell">
              <div class="viewers-wrapper">
                {{ data.item.viewers }}
              </div>
            </div>
          </template>
          <template #cell(views)="data">
            <div class="views-cell">
              <div class="views-wrapper">
                {{ data.item.views }}
              </div>
            </div>
          </template>
          <template #cell(timeOnPage)="data">
            <div class="avg-reading-time-cell">
              <div class="avg-reading-time-wrapper">
                {{ data.item.timeOnPage | timeString }}
              </div>
            </div>
          </template>
          <template #cell(bounceRate)="data">
            <div class="bounce-rate-cell">
              <div class="bounce-rate-wrapper">
                {{ data.item.bounceRate }}
              </div>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4 class="mb-4">{{ $t('settings.analytics.events') }}</h4>
        <div v-if="queryErrors.events" class="query-error">
          {{ $t('settings.analytics.eventsQueryError') }}
        </div>
        <template v-else-if="groupedEvents && Object.keys(groupedEvents).length">
          <b-card no-body class="mb-2" v-for="(events, category) in groupedEvents" :key="category">
            <b-card-header header-tag="header" role="tab">
              <b-button block v-b-toggle="category.replace(/\s+/g, '')" variant="dark" class="text-left event-category">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <b-icon icon="chevron-down" aria-hidden="true" scale="1" class="chevron-icon"></b-icon>
                    <span class="ml-2">{{ category }}</span>
                  </div>
                  <div class="event-summary">
                    <span class="mr-4">{{ getTotalVisitors(events) }} {{ $t('settings.analytics.visitors') }}</span>
                    <span>{{ getTotalCount(events) }} {{ $t('settings.analytics.events') }}</span>
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse :id="category.replace(/\s+/g, '')" accordion="events-accordion" role="tabpanel">
              <b-card-body class="p-0">
                <b-table
                  :items="events"
                  :fields="eventFields"
                  dark
                  borderless
                  fixed
                  class="mb-0"
                  :key="category.replace(/\s+/g, '')"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>
                  <template #cell(label)="data">
                    {{ (data.item.meta.label || '-').trim() }}
                  </template>
                  <template #cell(action)="data">
                    {{ data.item.meta.action }}
                  </template>
                  <template #cell(visitors)="data">
                    {{ data.item.visitors }}
                  </template>
                  <template #cell(count)="data">
                    {{ data.item.count }}
                  </template>
                </b-table>
              </b-card-body>
            </b-collapse>
          </b-card>
        </template>
        <p v-else>{{ $t('settings.analytics.noEvents') }}</p>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.referrers') }}</h4>
        <div v-if="queryErrors.referrers" class="query-error">
          {{ $t('settings.analytics.referrersQueryError') }}
        </div>
        <b-table
          v-else-if="referrers.length"
          :items="referrers"
          :fields="referrerFields"
          hover
          dark
          sort-by="visitors"
          sort-desc
          class="referrers-table"
        >
        </b-table>
        <p v-else>{{ $t('settings.analytics.noReferrers') }}</p>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="5">
        <h4>{{ $t('settings.analytics.deviceType') }}</h4>
        <div v-if="queryErrors.devices" class="query-error">
          {{ $t('settings.analytics.devicesQueryError') }}
        </div>
        <b-table
          v-else-if="devices.length"
          :items="devices"
          :fields="deviceFields"
          hover
          dark
          sort-by="visitors"
          sort-desc
          class="device-table"
        >
        </b-table>
        <p v-else>{{ $t('settings.analytics.noDevices') }}</p>
      </b-col>
      <b-col cols="12" sm="5">
        <h4>{{ $t('settings.analytics.countries') }}</h4>
        <div v-if="queryErrors.countries" class="query-error">
          {{ $t('settings.analytics.countriesQueryError') }}
        </div>
        <b-table
          v-else-if="countries.length"
          :items="countries"
          :fields="countryFields"
          hover
          dark
          sort-by="readers"
          sort-desc
          class="country-table"
        >
        </b-table>
        <p v-else>{{ $t('settings.analytics.noCountries') }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import GetPirschData from '@/graphQlQueries/queries/getPirschData';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import LineChart from '@/components/settings/analytics/LineChart.vue';
import GetAllChildPageIdsOfFolder from '@/graphQlQueries/queries/getAllChildPageIdsOfFolder';
import GetAllChildPageIdsOfProject from '@/graphQlQueries/queries/getAllChildPageIdsOfProject';
import thumbDefault from '@/assets/thumb-default.png';
import GetAllFoldersAndProjectsByFolderId from '@/graphQlQueries/queries/getAllFoldersAndProjectsByFolderId';

export default {
  name: 'PirschAnalyticsDashboard',
  components: { DateRangePicker, LineChart },
  props: {
    slugPathFromUrl: {
      type: String,
      default: null,
    },
    groupDomain: {
      type: String,
      default: null,
    },
    itemId: {
      type: Number,
      default: null,
    },
    itemType: {
      type: String,
      default: null,
    },
    pirschCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      queryErrors: {
        globalStats: false,
        readsPerDayObject: false,
        referrers: false,
        devices: false,
        countries: false,
        pages: false,
        projectOrFolders: false,
        events: false,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      minDate: null,
      globalStats: null,
      localeData: {
        direction: 'ltr',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: this.$t('buttons.apply'),
        cancelLabel: this.$t('buttons.cancel'),
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 0,
      },
      path: null,
      timezone: 'Europe/Berlin',
      switchDate: new Date('2024-04-01T00:00:00'),
      pirschDays: 0,
      readsPerDayObject: null,
      readsPerDayKey: 0,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      },
      referrers: [],
      devices: [],
      countries: [],
      fieldCountries: [
        {
          key: 'name',
          label: '',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'readers',
          label: this.$t('settings.analytics.readers'),
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      pages: [],
      pageTableKey: 0,
      fields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'image',
          tdClass: 'image',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'viewers',
          label: this.$t('settings.analytics.viewers'),
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          label: this.$t('settings.analytics.views'),
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'timeOnPage',
          label: this.$t('settings.analytics.timeOnPage'),
          sortable: true,
          width: '140px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'bounceRate',
          label: this.$t('settings.analytics.bounceRate'),
          sortable: true,
          width: '120px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      projectOrFolders: [],
      folderOrProjectTableKey: 0,
      projectAndFolderFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'image',
          tdClass: 'image',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'viewers',
          label: this.$t('settings.analytics.viewers'),
          sortable: true,
          width: '80px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          label: this.$t('settings.analytics.views'),
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'bounceRate',
          label: this.$t('settings.analytics.bounceRate'),
          sortable: true,
          width: '120px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      eventFields: [
        {
          key: 'label',
          label: 'Label',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          width: '150px',
        },
        {
          key: 'visitors',
          label: this.$t('settings.analytics.visitors'),
          sortable: true,
          width: '100px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'count',
          label: 'Events',
          sortable: true,
          width: '100px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      groupedEvents: null,
      referrerFields: [
        {
          key: 'referrer',
          label: this.$t('settings.analytics.referrers'),
          sortable: true,
        },
        {
          key: 'visitors',
          label: this.$t('settings.analytics.visitors'),
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'views',
          label: this.$t('settings.analytics.views'),
          sortable: true,
          class: 'text-center',
        },
      ],
      deviceFields: [
        {
          key: 'device',
          label: this.$t('settings.analytics.deviceType'),
          sortable: false,
        },
        {
          key: 'visitors',
          label: this.$t('settings.analytics.visitors'),
          sortable: true,
          class: 'text-center',
        },
      ],
      countryFields: [
        {
          key: 'name',
          label: this.$t('settings.analytics.countries'),
          sortable: false,
        },
        {
          key: 'readers',
          label: this.$t('settings.analytics.readers'),
          sortable: true,
          class: 'text-center',
        },
      ],
      showSwitchDateModal: false,
    };
  },
  computed: {
    showGroupDomainWarning() {
      return this.startDateBeforeSwitchDate;
    },
    startDateBeforeSwitchDate() {
      const startDate = new Date(this.dateRange.startDate);
      const switchDate = new Date(this.switchDate);
      return (startDate < switchDate);
    },
    switchDateFormat() {
      const date = this.switchDate;
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
  },
  created() {
    this.initializeDates();
    this.path = this.getPath();
  },
  methods: {
    initializeDates() {
      const endDate = new Date();
      endDate.setHours(23, 59, 59, 0);
      endDate.setMonth(endDate.getMonth());
      this.dateRange.endDate = endDate.toISOString();

      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setHours(0, 0, 0, 0);
      this.dateRange.startDate = startDate.toISOString();

      this.minDate = new Date('2022-06-21T00:00:00').toISOString();
    },
    getPath() {
      return this.slugPathFromUrl || '/';
    },
    getPathWithSlash() {
      return this.path.endsWith('/') ? this.path : `${this.path}/`;
    },
    updateValues(dateRange) {
      const startDate = new Date(dateRange.startDate);
      const switchDate = new Date(this.switchDate);

      if (startDate < switchDate) {
        this.showSwitchDateModal = true;
      }

      this.dateRange = {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
      };
      this.fetchStats();
      this.getThePageData();
      this.getTheProjectAndFolderData();
      this.getEvents();
    },
    async fetchStats() {
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: { isIndicatorLoading: true },
      });

      try {
        // Fetch global stats
        try {
          const pirschGlobalStats = await this.getPirschGlobalStats();
          const pirschTimeOnPage = await this.getPirschTimeOnPage();
          this.globalStats = this.getGlobalStats(
            pirschGlobalStats, pirschTimeOnPage,
          );
          this.queryErrors.globalStats = false;
        } catch (error) {
          console.error('Error fetching global stats:', error);
          this.queryErrors.globalStats = true;
        }

        // Fetch reads per day data
        try {
          const pirschReadsPerDay = await this.getPirschReadsPerDay();
          this.readsPerDayObject = this.getReadsPerDay(pirschReadsPerDay);
          this.readsPerDayKey += 1;

          this.queryErrors.readsPerDayObject = false;
        } catch (error) {
          console.error('Error fetching reads per day:', error);
          this.queryErrors.readsPerDayObject = true;
        }

        // Fetch referrers data
        try {
          const pirschReferrers = await this.getPirschReferrers();
          this.referrers = this.getReferrers(pirschReferrers);
          this.queryErrors.referrers = false;
        } catch (error) {
          console.error('Error fetching referrers:', error);
          this.queryErrors.referrers = true;
        }

        // Fetch device data
        try {
          const pirschDevices = await this.getPirschDevices();
          this.devices = this.getDevices(pirschDevices);
          this.queryErrors.devices = false;
        } catch (error) {
          console.error('Error fetching devices:', error);
          this.queryErrors.devices = true;
        }

        // Fetch countries data
        try {
          const pirschCountries = await this.getPirschCountries();
          this.countries = this.getCountries(pirschCountries);
          this.queryErrors.countries = false;
        } catch (error) {
          console.error('Error fetching countries:', error);
          this.queryErrors.countries = true;
        }
      } catch (error) {
        console.error('General Error while fetching data:', error);
      } finally {
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: { isIndicatorLoading: false },
        });
      }
    },
    async getEvents() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        try {
          const result = await this.$apollo.query({
            query: GetPirschData,
            variables: {
              obj: {
                endpoint: '/statistics/event/list',
                params: {
                  id: this.pirschCode,
                  from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                  to: this.dateRange.endDate.split('T')[0],
                  pattern: this.getItemPathId(),
                },
              },
            },
          });

          if (result.data.getPirschData.result) {
            const filteredEvents = result.data.getPirschData.result.filter(
              (event) => event.name !== 'Website-Unload' && event.name !== 'exit_intent',
            );
            this.groupedEvents = this.groupEventsByCategory(filteredEvents);
          }
          this.queryErrors.events = false;
        } catch (error) {
          console.error('Error fetching events:', error);
          this.queryErrors.events = true;
        }
      }
    },
    groupEventsByCategory(events) {
      return events.reduce((acc, event) => {
        const category = event.meta.category || 'Uncategorized';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(event);
        return acc;
      }, {});
    },
    getTotalVisitors(events) {
      return events.reduce((sum, event) => sum + event.visitors, 0);
    },
    getTotalCount(events) {
      return events.reduce((sum, event) => sum + event.count, 0);
    },
    async getPirschGlobalStats() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/total',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return null;
    },
    async getPirschTimeOnPage() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/duration/session',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
                scale: 'year',
              },
            },
          },
        });
        if (result.data.getPirschData.result) {
          let addedTime = 0;
          let counter = 0;
          result.data.getPirschData.result.forEach((item) => {
            if (item.average_time_spent_seconds) {
              addedTime += item.average_time_spent_seconds;
              counter += 1;
            }
          });
          return (counter > 0)
            ? (Math.round(addedTime)) / counter
            : 0;
        }
        return 0;
      }
      return null;
    },
    getGlobalStats(pirschData, pirschTimeOnPage) {
      const visitors = parseInt(pirschData?.visitors || 0, 10) || '-';
      const views = parseInt(pirschData?.views || 0, 10) || '-';
      const avgTime = parseFloat(pirschTimeOnPage) || 0;
      const bounceRate = parseFloat(pirschData?.bounce_rate || 0);

      return {
        visitors,
        views,
        avgTime,
        bounceRate: `${Math.round(bounceRate * 100)}%`,
      };
    },
    getItemPathId() {
      switch (this.itemType) {
        case 'folder':
          return `/f${this.itemId}`;
        case 'project':
          return `/pr${this.itemId}`;
        default:
          return '/';
      }
    },
    async getPirschReadsPerDay() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/visitor',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return null;
    },
    getReadsPerDay(pirschData) {
      const readsPerDayData = [];

      if (pirschData) {
        pirschData.forEach((item) => {
          readsPerDayData.push({
            label: item.day.split('T')[0],
            value: item.views,
          });
        });
      }

      readsPerDayData.sort((a, b) => a.label.localeCompare(b.label));
      return readsPerDayData;
    },
    async getPirschReferrers() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/referrer',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
                limit: 10,
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return [];
    },
    getReferrers(pirschData) {
      const referrerMap = new Map();

      if (pirschData && pirschData.length > 0) {
        pirschData.forEach((item) => {
          const referrer = item.referrer_name || item.referrer || 'Direct / None';
          if (referrerMap.has(referrer)) {
            const existing = referrerMap.get(referrer);
            existing.visitors = existing._visitors + item.sessions;
            existing.views = existing._views + parseInt(item.visitors, 10);
            existing._visitors = existing.visitors;
            existing._views = existing.views;
          } else {
            referrerMap.set(referrer, {
              referrer,
              visitors: item.sessions,
              views: parseInt(item.visitors, 10),
              _visitors: item.sessions,
              _views: parseInt(item.visitors, 10),
            });
          }
        });
      }

      return Array.from(referrerMap.values())
        .sort((a, b) => b._visitors - a._visitors)
        .slice(0, 10);
    },
    async getPirschDevices() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/platform',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return null;
    },
    getDevices(pirschData) {
      const deviceMap = new Map();

      if (pirschData && typeof pirschData === 'object') {
        const pirschTotal = pirschData.platform_desktop + pirschData.platform_mobile + pirschData.platform_unknown;
        if (pirschTotal > 0) {
          const deviceMapping = {
            desktop: 'desktop',
            mobile: 'mobile',
            unknown: 'unknown',
          };

          Object.entries(deviceMapping).forEach(([pirschDevice, mappedDevice]) => {
            const count = pirschData[`platform_${pirschDevice}`];
            const percentage = (count / pirschTotal) * 100;
            deviceMap.set(mappedDevice, {
              device: mappedDevice.charAt(0).toUpperCase() + mappedDevice.slice(1),
              visitors: `${Math.round(percentage)}%`,
              _visitors: percentage,
            });
          });
        }
      }

      ['desktop', 'mobile', 'unknown'].forEach((device) => {
        if (!deviceMap.has(device)) {
          deviceMap.set(device, {
            device: device.charAt(0).toUpperCase() + device.slice(1),
            visitors: '0%',
            _visitors: 0,
          });
        }
      });

      return Array.from(deviceMap.values());
    },
    async getPirschCountries() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/country',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return null;
    },
    getCountries(pirschData) {
      const countryMap = new Map();
      let totalVisitors = 0;

      if (pirschData) {
        pirschData.forEach((item) => {
          const { visitors, country_code } = item;
          const countryCode = country_code === '\u0000\u0000' ? 'unknown' : country_code.toLowerCase();
          countryMap.set(countryCode, {
            name: countryCode === 'unknown' ? 'Unknown' : country_code.toUpperCase(),
            visitors,
          });
          totalVisitors += visitors;
        });
      }

      const countries = Array.from(countryMap.values()).map((country) => {
        const percentage = Math.round((country.visitors / totalVisitors) * 100);
        return {
          name: country.name,
          readers: percentage === 0 ? `0% (${country.visitors})` : `${percentage}%`,
          _readers: percentage,
        };
      });

      return countries;
    },
    async getThePageData() {
      try {
        let childPages;
        if (this.itemType === 'project') {
          const result = await this.$apollo.query({
            query: GetAllChildPageIdsOfProject,
            variables: { projectId: this.itemId },
            fetchPolicy: 'network-only',
          });
          childPages = result.data.projects[0]?.child_pages || [];
        } else {
          const result = await this.$apollo.query({
            query: GetAllChildPageIdsOfFolder,
            variables: { folderId: this.itemId },
            fetchPolicy: 'network-only',
          });
          childPages = result.data.folders[0]?.child_pages || [];
        }
        const pirschData = await this.getPirschDataForChildPages();

        this.setChildPageData(childPages, pirschData);
      } catch (error) {
        console.error('Error fetching page data:', error);
        this.queryErrors.pages = true;
      }
    },
    async getPirschDataForChildPages() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/page',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
                include_avg_time_on_page: true,
              },
            },
          },
          fetchPolicy: 'network-only',
        });
        return result.data.getPirschData.result;
      }
      return [];
    },
    setChildPageData(childPages, pirschData) {
      this.pages = childPages.map((childPage) => {
        const itemPath = `/${childPage.item_path.replace(/\./g, '/')}`;
        const pirschPageData = pirschData ? pirschData.find((item) => item.path === itemPath) : null;

        const imageObject = {
          imageUrl: this.getPageImageUrl(childPage.thumbnails),
          focalpoint: childPage.thumbnails?.image?.focalpoint
            ? `${childPage.thumbnails.image.focalpoint.x}% ${childPage.thumbnails.image.focalpoint.y}%`
            : null,
        };
        return {
          name: childPage.name,
          viewers: pirschPageData ? pirschPageData.visitors : 0,
          views: pirschPageData ? pirschPageData.views : 0,
          timeOnPage: pirschPageData ? pirschPageData.average_time_spent_seconds : 0,
          bounceRate: pirschPageData ? `${Math.round(pirschPageData.bounce_rate * 100)}%` : '0%',
          imageObject,
        };
      });
      this.pageTableKey += 1;
    },
    getPageImageUrl(thumbnail) {
      let cloudImageUrl = null;
      if (thumbnail?.image?.url) {
        cloudImageUrl = this.$makeCloudImage(
          thumbnail.image.url,
          {
            width: 210,
            height: 150,
          },
          thumbnail.image,
        );
      }
      return (thumbnail?.image?.url)
        ? `url(${cloudImageUrl})`
        : `url(${thumbDefault})`; // Updated to use imported image
    },
    async getTheProjectAndFolderData() {
      if (this.itemType !== 'folder') return;

      this.$apollo.query({
        query: GetAllFoldersAndProjectsByFolderId,
        variables: {
          folderId: this.itemId,
        },
        fetchPolicy: 'network-only',
      }).then((result) => {
        const childItems = [
          ...result.data.folders.map((folder) => ({ ...folder, type: 'folder' })),
          ...result.data.projects.map((project) => ({ ...project, type: 'project' })),
        ];

        Promise.allSettled(childItems.flatMap((item) => [
          this.getPirschDataForChildItem(item),
        ])).then((results) => {
          // Process results and update projectOrFolders
          this.projectOrFolders = childItems.map((item) => {
            // find the result item by the item_path
            const pirschData = results.find((r) => r.value?.childPath === item.item_path)?.value || {};

            return {
              name: item.name,
              type: item.type,
              image: this.getItemImageUrl(item),
              viewers: pirschData.viewers || 0,
              views: pirschData.views || 0,
              timeOnPage: pirschData.timeOnPage || 0,
              bounceRate: pirschData.bounce_rate ? `${Math.round(pirschData.bounce_rate * 100)}%` : '0%',
            };
          });

          // Trigger a re-render of the table
          this.folderOrProjectTableKey += 1;
        }).catch((error) => {
          console.error('Error fetching Analytics data:', error);
          this.queryErrors.projectOrFolders = true;
          this.projectOrFolders = childItems.map((item) => ({
            name: item.name,
            type: item.type,
            image: this.getItemImageUrl(item),
            viewers: 0,
            views: 0,
            timeOnPage: 0,
            bounceRate: 0,
          }));
        });
      });
    },
    getItemImageUrl(item) {
      if (item.type === 'folder') {
        return null; // Folders don't have images, we'll use an icon instead
      }
      let cloudImageUrl = null;
      if (item.thumbnails?.cover_image?.url) {
        cloudImageUrl = this.$makeCloudImage(
          item.thumbnails.cover_image.url,
          { width: 210, height: 150 },
          item.thumbnails.cover_image,
        );
      }
      return (item.thumbnails?.cover_image?.url)
        ? `url(${cloudImageUrl})`
        : `url(${thumbDefault})`;
    },
    getPirschDataForChildItem(childItem) {
      if (new Date(this.dateRange.endDate) < this.switchDate) {
        return Promise.resolve(null);
      }

      const itemPath = `/${childItem.item_path.replace(/\./g, '/')}`;
      if (!itemPath || !this.pirschCode) {
        return Promise.resolve(null);
      }

      return this.$apollo.query({
        query: GetPirschData,
        variables: {
          obj: {
            endpoint: '/statistics/total',
            params: {
              id: this.pirschCode,
              from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
              to: this.dateRange.endDate.split('T')[0],
              pattern: itemPath,
              include_avg_time_on_page: true,
            },
          },
        },
        fetchPolicy: 'network-only',
      }).then((response) => {
        if (!response.data || !response.data.getPirschData || !response.data.getPirschData.result) {
          throw new Error('Invalid response structure from Pirsch API');
        }
        const { result } = response.data.getPirschData;
        return {
          viewers: parseInt(result.visitors, 10) || 0,
          views: parseInt(result.views, 10) || 0,
          timeOnPage: result.average_time_spent_seconds || 0,
          bounce_rate: parseFloat(result.bounce_rate) || 0,
          childPath: childItem.item_path,
        };
      }).catch((error) => {
        console.error('Error fetching Pirsch data for child item:', error);
        return {
          viewers: 0,
          views: 0,
          timeOnPage: 0,
          bounce_rate: 0,
          childPath: childItem.item_path,
        };
      });
    },
    getItemData(pirschData) {
      const entries = pirschData ? pirschData.visits : 0;
      const views = pirschData ? pirschData.pageviews : 0;
      const avgReadingTime = pirschData ? pirschData.avg_duration : 0;
      const bounceRate = pirschData ? pirschData.bounce_rate : 0;

      return {
        entries: entries || 0,
        views: views || 0,
        avgReadingTime: avgReadingTime || 0,
        bounceRate: bounceRate ? `${Math.round(bounceRate * 100)}%` : 0,
      };
    },
    calculateWeightedAverage(value1, value2, weight1, weight2) {
      const totalWeight = weight1 + weight2;
      if (totalWeight === 0) return 0;
      return ((value1 * weight1) + (value2 * weight2)) / totalWeight;
    },
    openEmail() {
      const subject = this.$t('settings.analytics.helpEmailSubject', { switchDate: this.switchDateFormat });
      const body = this.$t('settings.analytics.helpEmailBody', {
        switchDate: this.switchDateFormat,
        domain: this.groupDomain,
        path: this.getPathWithSlash(),
        startDate: this.dateRange.startDate.split('T')[0],
        endDate: this.dateRange.endDate.split('T')[0],
      });
      window.location.href = `mailto:support@webmag.io?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      this.showSwitchDateModal = false;
    },
  },
  filters: {
    timeString(value) {
      if (!value) return '0';
      const totalSeconds = Math.floor(value);
      const seconds = totalSeconds % 60;
      const minutes = Math.floor(totalSeconds / 60);
      return (minutes > 0)
        ? `${minutes}min ${seconds}s`
        : `${seconds}s`;
    },
    inputDate(value) {
      if (!value) return value;
      const timestamp = new Date(value);
      if (!timestamp) return value;
      const month = timestamp.getMonth() + 1;
      const day = timestamp.getDate();
      return `${day}.${month}`;
    },
  },
  mounted() {
    this.fetchStats();
    this.getThePageData();
    this.getTheProjectAndFolderData();
    this.getEvents();
  },
};
</script>

<style scoped lang="scss">
/deep/ .daterangepicker .calendar-table td, /deep/ .daterangepicker .calendar-table th {
  border-radius: 0;
}

h3 {
  line-height: 38px;
}

.domain-select {
  max-width: 350px;

  select {
    width: 100%;
  }
}

h4, h5 {
  text-transform: uppercase;
  font-weight: 600;
}

h4 {
  color: #fff;
}

.global-stats {
  h5 {
    font-size: 13px;
    color: #b9b9b9;
  }

  div {
    font-size: 33px;
    font-weight: 400;
    color: #fff
  }
}

.table-dark {
  background-color: transparent;
}

/deep/ table {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
    border-color: transparent;
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &.image:first-child {
        padding: 0;
        max-width: 50px;
      }

      &.center {
        text-align: center;
        font-weight: 700;
      }

      &:nth-child(4) {
        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          li {
            display: block;
            padding: 0 5px;
            margin-right: 6px;
            font-size: 13px;
            font-weight: 700;
            color: #44484C;
            background-color: #B9B9B9;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .cell-background-image {
    width: 100%;
    height: 100%;
    min-height: 36px;
    min-width: 50px;
    background-color: #777C7F;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cell-folder {
    background: rgba(83, 220, 134, 0.7);

    svg {
      fill: white;
    }
  }
}

hr {
  margin-top: 0;
}

.dropdown-icon {
  color: #A9B1B5;
  position: absolute;
  right: 10px;
  top: 10px;
}

/deep/ .reportrange-text {
  position: relative;
  background: #53575B;
  color: #B9B9B9;
  border: 1px solid #707070;
  padding: 10px 50px 2px 15px;
  line-height: 16px;
}

/deep/ #line-chart {
  min-height: 400px;
}

#content .query-error {
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  background-color: #53575B;
  border: 1px solid rgba(220, 83, 83, 0.7);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  font-size: 15px;
  padding: 7px 8px;
}
.country-table /deep/ tr > td {
  text-transform: uppercase;
}

.stats-breakdown {
  color: #b9b9b9;

  h5 {
    color: #fff;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .stat-value {
    font-weight: bold;
    color: #fff;
  }
}

.table-simple {
  width: 100%;

  th, td {
    padding: 10px;
    text-align: left;
  }

  th {
    font-weight: bold;
    text-transform: uppercase;
  }

  tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.device-table, .country-table, .referrers-table {
  th:nth-child(2), th:nth-child(3) {
    width: 110px;
    text-align: center;
  }
  td:nth-child(2), td:nth-child(3) {
    text-align: center;
  }
}

.cell-background-image {
  width: 100%;
  height: 100%;
  min-height: 36px;
  min-width: 50px;
  background-color: #777C7F;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.cell-folder {
  background: rgba(83, 220, 134, 0.7);

  svg {
    fill: white;
  }
}

.category {
  background-color: #53575B;
  border: none !important;

  &:hover, &:focus {
    background-color: #53575B !important;
  }
}
.event-category {
  background-color: #53575B;
  border: none !important;
}

.summary {
  font-size: 0.9em;
  color: #B9B9B9;
}

/deep/ .card {
  background-color: transparent;
  border: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  .card-header {
    background-color: transparent;
    border: none;
    padding: 0;
    .chevron-icon {
      transition: transform 0.2s ease;
    }

    .not-collapsed .chevron-icon {
      transform: rotate(180deg);
    }
  }

  .card-body {
    background-color: #44484c;
  }

  table {
    margin-bottom: 0 !important;

    tbody tr {
      background-color: transparent;
      box-shadow: none;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
}
</style>
